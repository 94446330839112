import { configureStore } from '@reduxjs/toolkit';

import appReducer from './app.reducer';
// import overviewReducer from './modules/home/overview/overview.reducer';
// import auditReducer from './modules/audit/audit.reducer';
// import agentReducer from './modules/agents/agent.reducer';
// import insightReducer from './modules/insight-detail/insight-detail.reducer';
// import workflowReducer from './modules/workflow/workflow.reducer';
// import feedbackReducer from './modules/home/feedback/feedback.reducer';

export default configureStore({
  reducer: {
    app: appReducer,
    // audit: auditReducer,
    // agent: agentReducer,
    // overview: overviewReducer,
    // insight: insightReducer,
    // workflow: workflowReducer,
    // feedback: feedbackReducer,
  },
});
