export const API = {
  GET: {
    GET_USER_INFO: '/user/details/',
    COUNTRIES: '/countries/',
    CITIES: '/cities/',
    REGIONS: '/regions/',
    CATEGORIES: '/categories/',
    tiles: '/:category/categories/',
  },
  POST: {
    UPLOAD: '/v0/upload',
    LOGIN: '/user/login/',
    TOKEN_REFRESH: '/token/refresh/',
    SEND_OTP: '/user/resend_activation/',
    VERIFY_OTP: '/user/verify_otp/',
    SIGNUP: '/user/',
    MERCHANT: '/merchants/',
    FORGOT_PASSWORD: '/user/forgot_password/',
  },
  PUT: {
    LOGOUT: '/user/logout',
    MARK_READ: '/rest/event/call_read',
    CHANGE_PASSWORD: '/user/password/change',
  },
};
