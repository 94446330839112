import React, { useState, useEffect, useContext} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { arrowForwardCircleOutline } from 'ionicons/icons'

import { API } from '../common/constants/api.constant';
import useHTTP from '../common/hooks/http';
import useToastr from '../common/hooks/toastr';
import { AuthContext } from '../common/context/auth.context';
import './home.scss';

const Home = () => {
    const toastr = useToastr();
    const $http = useHTTP(process.env.REACT_APP_API_URL);
    const authContext = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        if (!authContext.userLoggedIn) {
            history.push(`/account/login`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    // useEffect(() => {
    //     $http
    //         .get(`${API.GET.CATEGORIES}`)
    //         .then((res) => {
    //             console.log(res);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             toastr.error(err.data.message);
    //         });
    // }, []);

    return (
        <div className="home-container container">
            <div className="row">
                <div className="col-xs-12 col-sm-3">
                    <Link to="/tiles/categories">
                        <div className="card">
                            <div className="card__body">
                                <div className="card__body__header font-2xl-bold">Tiles</div>
                                <div className="card__link">
                                    View All Categories 
                                    <span className="icon"><IonIcon icon={arrowForwardCircleOutline}></IonIcon></span>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Home;