import React from 'react';
import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';

import Home from './modules/home';
import Category from './modules/category/list';
import Product from './modules/product/list';
import AddProduct from './modules/product/add';
import ProductDetail from './modules/productDetail';
import { Header } from './modules/common/components/header'
import Account from './modules/account/account';
import Profile from './modules/account/profile/profile';
import AddCategory from './modules/category/add';
import StaffList from './modules/staff/staff-list';
import AddStaff from './modules/staff/staff-add';
import ProductLog from './modules/productLog';
import ViewProfile from './modules/profile/view profile';

function AppRouting() {
    const routes = [
        {
          path: '/',
          component: Home,
        },
        {
          path: '/:type/categories',
          component: Category,
        },
        {
            path: '/:type/category/add',
            component: AddCategory,
        },
        {
            path: '/:type/:category/product/edit/:productId',
            component: AddProduct,
        },
        {
            path: '/:type/:category/product/add',
            component: AddProduct,
        },
        {
            path: '/:type/:category/products',
            component: Product,
        },
        {
            path: '/:type/:category/products/:productId',
            component: ProductDetail,
        },
        {
            path: '/:type/:category/products/:productId/logs',
            component: ProductLog,
        },
        {
            path: '/staffs/edit/:userId',
            component: AddStaff,
        },
        {
            path: '/staffs/add',
            component: AddStaff,
        },
        {
            path: '/staffs',
            component: StaffList,
        },
        {
          path: '/account/:action',
          component: Account,
          noHeader: true,
        },
        {
            path: '/my-profile',
            component: Profile,
            exact: false,
        },
        {
            path: '/profile',
            component: ViewProfile,
        },
        // {
        //   path: '/insights',
        //   component: Insight,
        // },
        // {
        //   path: '/insight/:category',
        //   component: InsightDetail,
        // },
    ];

    const renderChildren = (props: any, route: any) =>
        route.noHeader ? (
            <route.component {...props} />
            ) : (
            <>
                <Header />
                <route.component {...props} />
            </>
        );
    
    return (
            <IonSplitPane contentId="main">
                <IonRouterOutlet id="main">
                    {/* <Route path="/" exact={true}>
                        <Redirect to="/page/Inbox" />
                    </Route>
                    <Route path="/page/:name" exact={true}>
                        <Page />
                    </Route> */}
                    {routes.map((route, i) =>
                        route.exact === false ? (
                            <Route
                                key={i}
                                path={route.path}
                                render={(props) => renderChildren(props, route)}
                            />
                        ) : (
                            <Route
                                exact
                                key={i}
                                path={route.path}
                                render={(props) => renderChildren(props, route)}
                            />
                        )
                    )}
                </IonRouterOutlet>
            </IonSplitPane>
    );
}

export default AppRouting;