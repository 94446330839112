import React from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';

import { loaderVisible } from '../../../../app.reducer';

import './loader.scss';

function Loader() {
  const loader = useSelector(loaderVisible);

  return (
    <>
      {loader ? (
        <div className="loader">
          <div className="spinner">
            {/* <i className="fa fa-spinner"></i> */}
            <Spin />
          </div>
        </div>
      ) : null}
    </>
  );
}

export default Loader;
