import React, { useState, useEffect, useContext, useRef, useCallback} from 'react';
import { Link, useParams } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { arrowForwardCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { Breadcrumb, Popconfirm } from 'antd';
import { useDebouncedEffect } from '../../common/hooks/useDebouncedEffect';

import useHTTP from '../../common/hooks/http';
import useToastr from '../../common/hooks/toastr';
import { AuthContext } from '../../common/context/auth.context';
import Products from '../../product/list/products'; 
import '../../product/list/product.scss';
import './category.scss';
import { paginationClasses } from '@mui/material';

const Category = () => {
    const toastr = useToastr();
    const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);
    const { userDetail } = useContext(AuthContext);
    const {type } = useParams();
    const [categories, setCategories] = useState([]);
    const containerRef = useRef(null);
    const [page, setPage] = useState(1);
    const [loadedTotal, setLoadedTotal] = useState(false);
    const isLoading = useRef(false);
    const [next, setNext] = useState('');
    const [textInput, setTextInput] = useState('');
// Product search
    const [products, setProducts] = useState([]);
    const prodPage = useRef();
    const [total, setTotal] = useState();
    const prodLoadedTotal= useRef(false);
    const prodContainerRef = useRef(null);

    const fetchData = () => {
        if (isLoading.current) return;
        isLoading.current = true;
        $http
            .get(`${process.env.REACT_APP_API_URL}/tiles/categories/?page=${page}${textInput?'&search=':''}${textInput? textInput : ''}`)
            .then((res) => {
                if (page === 1) {
                    setCategories(res.results);
                } else {
                    setCategories([...categories, ...res.results]);;
                }
                
                isLoading.current = false;
                setNext(res.next)
            })
            .catch((err) => {
                console.log(err);
                toastr.error(err.data.message);
            });
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
        console.log(containerRef);
        console.log(scrollHeight, scrollTop, clientHeight);

        if (scrollHeight - scrollTop - 2 <= clientHeight) {
            if (next) {
                setPage(page + 1);
            }
        }
        // fetchData();
      };

      useEffect(() => {
        fetchData(paginationClasses);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [page]);
      

    const deleteCategory = (id) => {
        $http
            .deleteReq(`${process.env.REACT_APP_API_URL}/${type}/categories/${id}/`, {})
            .then((res) => {
                const message = `Tile Size Deleted Successfully!`;
                toastr.info(message);
                setCategories(categories.filter(c => c.id !== id));
            })
            .catch((err) => {
                console.log(err);
                toastr.error(err.data.message);
            });
    }

    const fetchProdData = useCallback(() => {
        if (!prodLoadedTotal.current) {
            $http
            .get(`${process.env.REACT_APP_API_URL}/${type}/products/?page=${prodPage.current}${textInput?'&search=':''}${textInput? textInput : ''}`)
            .then((res) => {
                if (prodPage.current === 1) {
                    setProducts(res.results);
                } else {
                    setProducts([...products, ...res.results]);
                }
                
                setTotal(res.count);
                if (res.next) {
                    prodPage.current = prodPage.current + 1;
                } else {
                    prodLoadedTotal.current = true;
                }
            })
            .catch((err) => {
                console.log(err);
                toastr.error(err.data.message);
            });
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prodPage, products, textInput]);

    const handleProdScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = prodContainerRef.current;
        console.log(containerRef);
        console.log(scrollHeight, scrollTop, clientHeight);

        if (scrollHeight - scrollTop - 2 <= clientHeight) {
            fetchProdData();
        }
        // fetchData();
    };

    useDebouncedEffect(
        () => {
            prodLoadedTotal.current = false;
            prodPage.current = 1;
            setProducts([]);
            fetchProdData();
        },
        1000,
        [textInput]
    );

    const handleChange = (val) => {
        setTextInput(val);
    }

    return (
        <div ref={textInput.length > 0 ? prodContainerRef : containerRef} className='category-container' onScroll={ textInput.length > 0 ? handleProdScroll : handleScroll}>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>Categories</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-xs-12 col-sm-6 text-right mb-4 mt-4">
                        <input placeholder="Search" value={textInput} onChange={(e) => handleChange(e.target.value)} style={{ width: '100%', padding: '.7rem 1rem', border: '2px solid #ddd', borderRadius: '1rem'}} />
                    </div>
                    {
                        userDetail?.permission !== 'Viewer' && 
                        <div className="col-xs-12 col-sm-12 text-right mb-4">
                            <Link className="add-btn" to={`/${type}/category/add`}>Add Category</Link>
                        </div>
                    }
                    {
                        textInput.length > 0 ?
                        <div className="row products-container px-4">
                            <Products 
                                productList={products}
                            />
                        </div>
                        :
                        categories.map(cat => (
                            <div className="col-xs-12 col-sm-3 category" key={cat.id}>
                                <div className="card">
                                    <div className="card__body">
                                        {
                                            userDetail?.permission !== 'Viewer' && 
                                            <span className="action">
                                                <Popconfirm
                                                    title={<>Are you sure to delete this category?<br/> <b>Deleting this category will delete all the products of this category.</b></>}
                                                    onConfirm={() => deleteCategory(cat.id)}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <a href="#">
                                                        <IonIcon name='Delete Category' icon={closeCircleOutline} />
                                                    </a>
                                                </Popconfirm>
                                            </span>
                                        }
                                        <div className="card__body__header font-2xl-bold" style={{textTransform: 'uppercase'}}>{cat.name}</div>
                                        <Link to={`/${type}/${cat.id}/products`}>
                                            <div className="card__link">
                                                View All Products 
                                                <span className="icon"><IonIcon icon={arrowForwardCircleOutline}></IonIcon></span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {/* <div ref={observerTarget}></div> */}
            </div>
        </div>
    )
}

export default Category;