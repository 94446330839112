import React, { useState, useEffect, useContext, useCallback, useRef} from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { arrowForwardCircleOutline, createOutline, arrowBackCircleOutline } from 'ionicons/icons';
import { Breadcrumb, InputNumber, Modal, Popconfirm } from 'antd';

import useHTTP from '../common/hooks/http';
import useToastr from '../common/hooks/toastr';
import { AuthContext } from '../common/context/auth.context';
import './log.scss';
import '../home/home.scss';
import moment from 'moment';

const ProductLog = () => {
    const toastr = useToastr();
    const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);
    const {type, category, productId } = useParams();
    const { userDetail } = useContext(AuthContext);
    const [logs, setLogs] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState();
    const [loadedTotal, setLoadedTotal] = useState(false);
    const containerRef = useRef(null);
    const history = useHistory();

    const fetchData = useCallback(() => {
        if (!loadedTotal) {
            $http
            .get(`${process.env.REACT_APP_API_URL}/${type}/products/log/?product_id=${productId}&page=${page}`)
            .then((res) => {
                setLogs([...logs, ...res.results]);
                setTotal(res.count);
                if (res.next) {
                    setPage(page + 1);
                } else {
                    setLoadedTotal(true);
                }
            })
            .catch((err) => {
                console.log(err);
                toastr.error(err.data.message);
            });
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, logs, loadedTotal]);

    const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
        if (scrollHeight - scrollTop - 2 <= clientHeight) {
            fetchData();
        }
        // fetchData();
    };
      
    useEffect(() => {
        fetchData();
    }, []);

    const goBack = () => {
        history.goBack();
    }




    return (
        <div ref={containerRef} className='product-container' onScroll={handleScroll}>
            <div className="container">
                <div className="row mb-4">
                    <div className="col-3 col-sm-1 back-btn">
                        <button className="link" onClick={goBack}><span className="icon"><IonIcon icon={arrowBackCircleOutline}></IonIcon></span> Back</button>
                    </div>
                </div>
                {
                    logs.map((log, index) => (
                        <div  className="row font-2xl-regular mb-4 pb-4" key={index} style={{borderBottom: '1px solid #eee'}}>
                            <div className="col-1" style={{whiteSpace: 'nowrap'}}>
                                {`
                                ${index+1}.
                                `} 
                            </div>  
                            <div className="col-11">
                                {`
                                ${Math.abs(log.quantity)} quantity ${log.quantity >=0 ? 'added' : 'sold'} by ${log.created_by} on ${moment(log.created_at).format('YYYY-MM-DD hh:mm A')}
                                `} 
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default ProductLog;