import React, { useState, useEffect, useContext, useCallback, useRef} from 'react';
import { Link, useParams } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { arrowForwardCircleOutline, createOutline, closeCircleOutline } from 'ionicons/icons';
import { Breadcrumb, InputNumber, Modal, Popconfirm, Search } from 'antd';

import useHTTP from '../../common/hooks/http';
import useToastr from '../../common/hooks/toastr';
import { AuthContext } from '../../common/context/auth.context';
import { useDebouncedEffect } from '../../common/hooks/useDebouncedEffect';
import Products from './products'; 
import '../../home/home.scss'

const Product = () => {
    const toastr = useToastr();
    const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);
    const {type, category } = useParams();
    const { userDetail } = useContext(AuthContext);
    const [products, setProducts] = useState([]);
    const page = useRef();
    const [total, setTotal] = useState();
    const loadedTotal= useRef(false);
    const containerRef = useRef(null);
    const [textInput, setTextInput] = useState('');

    const fetchData = useCallback(() => {
        if (!loadedTotal.current) {
            $http
            .get(`${process.env.REACT_APP_API_URL}/${type}/products/?category_id=${category}&page=${page.current}${textInput?'&search=':''}${textInput? textInput : ''}`)
            .then((res) => {
                if (page.current === 1) {
                    setProducts(res.results);
                } else {
                    setProducts([...products, ...res.results]);
                }
                
                setTotal(res.count);
                if (res.next) {
                    page.current = page.current + 1;
                } else {
                    loadedTotal.current = true;
                }
            })
            .catch((err) => {
                console.log(err);
                toastr.error(err.data.message);
            });
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, products, textInput]);

    const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = containerRef.current;
        console.log(containerRef);
        console.log(scrollHeight, scrollTop, clientHeight);

        if (scrollHeight - scrollTop - 2 <= clientHeight) {
            fetchData();
        }
        // fetchData();
    };

    useDebouncedEffect(
        () => {
            loadedTotal.current = false;
            page.current = 1;
            setProducts([]);
            fetchData();
        },
        1000,
        [textInput]
    );

    const handleChange = (val) => {
        setTextInput(val);
    }


    return (
        <div ref={containerRef} className='products-container' onScroll={handleScroll}>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-sm-12">
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={`/${type}/categories`}>Categories</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>Products</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-6 text-right mb-4 mt-4">
                        <input placeholder="Search" value={textInput} onChange={(e) => handleChange(e.target.value)} style={{ width: '100%', padding: '.7rem 1rem', border: '2px solid #ddd', borderRadius: '1rem'}} />
                    </div>
                    {
                        userDetail?.permission !== 'Viewer' && 
                        <div className="col-xs-12 col-sm-6 text-right mb-4">
                            <Link className="add-btn" to={`/${type}/${category}/product/add`}>Add Product</Link>
                        </div>
                    }
                    <Products 
                        productList={products}
                    />
                </div>
            </div>
        </div>
    )
}

export default Product;