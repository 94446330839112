import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form, Input, Upload, Select, Radio } from 'antd';
import { IonIcon } from '@ionic/react';
import { arrowBackCircleOutline } from 'ionicons/icons';

import useHTTP from '../../common/hooks/http';
import useToastr from '../../common/hooks/toastr';
import './staff-add.scss';


const { TextArea } = Input;
const Option = Select.Option;
const PERMISSIONS = [
    { 
        value: 'Admin',
        label: 'Admin',
    },
    { 
        value: 'Manager',
        label: 'Manager',
    },
    { 
        value: 'Viewer',
        label: 'Viewer',
    },
]

const AddStaff = () => {
    const history = useHistory();
    const toastr = useToastr();
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const {userId } = useParams();
    const $http = useHTTP(process.env.REACT_APP_ACCOUNT_URL);
    const [formData, setFormData] = useState({});
    const [showOTP, setShowOTP] = useState(false);
    // const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (userId) {
        fetchUser();
    }
   
  }, []);

  const fetchUser = () => {
    $http
        .get(`${process.env.REACT_APP_API_URL}/merchant/staffs/${userId}/`)
        .then((res) => {
            console.log(form);
            form.setFieldsValue(res);
            // setFileList(res.tile_images.map((tile, index) => {
            //     return {
            //         uid: index,
            //         url: tile.image,
            //         status: 'done',
            //         name: index,
            //         response: {
            //             image_url: tile.image
            //         }
            //     }
                
            // }));
        })
        .catch((err) => {
            console.log(err);
            toastr.error(err.data.message);
        });
  }

  const onError = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const submitOtp  = (data) => {
    console.log(data);
    const payload = {
        ...formData,
        otp: data.otp
    }
    if (userId) {
        $http
        .patch(`${process.env.REACT_APP_API_URL}/merchant/staffs/${userId}/`, payload)
        .then((res) => {
            const message = `User updated successfully!`;
            toastr.info(message);
            history.push(`/staffs`);
        })
        .catch((err) => {
            console.log(err);
            toastr.error(err.data.message);
        });
    } else {
        $http
        .post(`${process.env.REACT_APP_API_URL}/merchant/staffs/`, payload)
        .then((res) => {
            const message = `User added successfully!`;
            toastr.info(message);
            history.push(`/staffs`);
        })
        .catch((err) => {
            console.log(err);
            toastr.error(err.data.message);
        });
    }
  }

  const sendOtp = () => {
    $http
    .post(`${process.env.REACT_APP_API_URL}/merchant/staffs/`, {})
    .then((res) => {
        const message = `OTP sent to your mobile!`;
        toastr.info(message);
    })
    .catch((err) => {
        console.log(err);
        toastr.error(err.data.message);
    });
  }

  const addUser = (values) => {
    if (values.phone) {
        values.phone = `+91${values.phone}`;
    }
    const data = { 
        type: "phone",
      ...values,
    };
    sendOtp();
    setFormData(data);
    setShowOTP(true);
  };


  const resetForm = () => {
    form.resetFields();
  }

  const goBack = () => {
    history.goBack();
  }

  return (
    <>
      <div className="product-form">
        <div className="row">
            <div className="col-sm-12 col-xs-12 back-btn">
                <button className="link" onClick={goBack}><span className="icon"><IonIcon icon={arrowBackCircleOutline}></IonIcon></span> Back</button>
            </div>
        </div>
        <div className="row my-4 px-0">
          <div className="col-sm-12 col-xs-12 font-3xl-semibold">{userId ? 'Edit':'ADD'} Staff Member</div>
        </div>
        {
            showOTP ? 
            <Form
                name="basic"
                layout="vertical"
                form={form2}
                onFinish={submitOtp}
                onFinishFailed={onError}
            >
                <Form.Item
                label="OTP"
                name="otp"
                rules={[
                    { required: true, message: 'Please enter your otp!' },
                    { pattern: /^\d{6}$/, message: 'Please enter a valid otp' },
                ]}
                >
                <Input placeholder="Enter otp" />
                </Form.Item>
                <Form.Item>
                    <button 
                        type="button" 
                        className="btn btn-reset font-2xl-regular"
                        onClick={sendOtp}
                    >
                        Resend
                    </button>
                    <button type="submit" className="btn btn-submit font-2xl-regular">
                        Submit
                    </button>
                </Form.Item>
            </Form>
        :
            <Form
                name="basic"
                layout="vertical"
                form={form}
                onFinish={addUser}
                onFinishFailed={onError}
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: 'Please enter name!' }]}
                >
                    <Input placeholder="Enter Name" />
                </Form.Item>
                <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                    { required: true, message: 'Please enter your mobile!' },
                    { pattern: /^\d{10}$/, message: 'Please enter a valid mobile number' },
                ]}
                >
                <Input placeholder="Enter Phone Number" disabled={!!userId} />
                </Form.Item>
                <Form.Item
                    label="ADD ROLE"
                    name="permission"
                    rules={[{ required: true, message: 'Please enter application type!' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select Role"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={PERMISSIONS}
                    />
                </Form.Item>
                <Form.Item
                    label="Active"
                    name="active"
                >
                    <Radio.Group defaultValue={true}>
                        <Radio value={true} >Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item> 
                <Form.Item>
                    <button 
                        type="button" 
                        className="btn btn-reset font-2xl-regular"
                        onClick={goBack}
                    >
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-submit font-2xl-regular">
                        {userId ? 'UPDATE':'ADD'}
                    </button>
                </Form.Item>
            </Form>
        }
      </div>
    </>
  );
};

export default AddStaff;